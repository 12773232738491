import Link from 'next/link'
import { Disclosure, Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Dispatch, Fragment, useEffect, useState } from 'react'
import useDarkMode from '@/hooks/useDarkMode'
import UserAuth from '@/components/Auth/UserAuth'
import ThemeSwitch from '@/components/Layout/ThemeSwitch'

const navItems = [
  {
    url: '/search',
    text: 'Search',
    id: 'nav:home_button_click',
  },
  {
    url: '/blog',
    text: 'Blog',
    id: 'nav:blog_button_click',
  },
  {
    url: '/about',
    text: 'About',
    id: 'nav:about_button_click',
  },
  {
    url: '/contact',
    text: 'Contact',
    id: 'nav:contact_button_click',
  },
  {
    url: '/support',
    text: 'Support Us',
    id: 'nav:support_button_click',
  },
]

const secondaryNavItems = [
  {
    url: '/filters',
    text: 'Filter Definitions',
    id: 'sidebar:filter_button_click',
  },
  {
    url: '/community-for-professionals',
    text: 'Community For Professionals',
    id: 'sidebar:improve_button_click',
  },
  {
    url: '/improve',
    text: 'Improve YumDodo',
    id: 'sidebar:improve_button_click',
  },
  {
    url: '/terms-of-service',
    text: 'Terms of Service',
    id: 'sidebar:tos_button_click',
  },
  {
    url: '/privacy-policy',
    text: 'Privacy Policy',
    id: 'sidebar:privacy_button_click',
  },
  {
    url: '/help',
    text: ' Ways to Help',
    id: 'sidebar:help_button_click',
  },
]

const Navbar = () => {
  const [colorTheme, setTheme] = useDarkMode()

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <Disclosure
      as="nav"
      className="bg-white dark:bg-dark dark:text-white shadow mb-2"
    >
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex flex-shrink-0 items-center">
                <Link
                  href={'/'}
                  className="inline-flex items-center border-b-2 border-transparent pt-1 text-sm font-medium text-gray-500"
                >
                  {mounted && (
                    <img
                      className="h-4 w-auto"
                      src={
                        colorTheme === 'dark'
                          ? '/assets/logo/white.svg'
                          : '/assets/logo/dark.svg'
                      }
                      alt="YumDodo"
                    />
                  )}
                </Link>
              </div>

              <div className="flex-1 pl-0 lg:pl-2 self-center">
                <div className="flex px-2 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                  {mounted && (
                    <div className="w-full flex flex-row gap-2 justify-end">
                      <a
                        href="https://www.instagram.com/yumdodoeats/"
                        target="_blank"
                      >
                        <img
                          className="w-6 fill-current  stroke-white dark:rounded-lg"
                          src={
                            colorTheme === 'dark'
                              ? '/assets/social/instagram/dark.svg'
                              : '/assets/social/instagram/white.svg'
                          }
                          alt={'instagram'}
                        />
                      </a>
                      <a
                        href="https://www.tiktok.com/@yumdodoeats"
                        target="_blank"
                      >
                        <img
                          className="w-6"
                          src={
                            colorTheme === 'dark'
                              ? '/assets/social/tiktok/dark.svg'
                              : '/assets/social/tiktok/white.svg'
                          }
                          alt={'tiktok'}
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="items-center inline-flex lg:ml-6 lg:hidden lg:space-x-8 px-4">
                <UserAuth />
              </div>
              <div className="hidden md:ml-6 lg:flex md:space-x-8">
                {navItems.map((item) => (
                  <Link
                    id={item.id}
                    key={item.text}
                    href={item.url}
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 dark:text-white hover:border-gray-300 hover:dark:text-white hover:text-gray-700"
                  >
                    {item.text}
                  </Link>
                ))}

                <Popover className="relative inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700  focus:outline-none">
                  <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-500 dark:text-white focus:outline-none">
                    <span>More</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-1/4 mt-96 flex w-screen max-w-min -translate-x-1/2 px-4  z-[3000]">
                      <div className="w-56 shrink rounded-xl bg-white dark:bg-dark p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5]">
                        {secondaryNavItems.map((item) => (
                          <Link
                            id={item.id}
                            key={item.text}
                            href={item.url}
                            className="block p-2 hover:border-gray-300 hover:text-gray-400 dark:text-white"
                          >
                            {item.text}
                          </Link>
                        ))}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>

                <div className="inline-flex items-center">
                  <UserAuth />
                </div>
                <div className="inline-flex items-center">
                  {mounted && (
                    <ThemeSwitch
                      state={colorTheme === 'dark'}
                      onClick={() =>
                        setTheme((prev: string | Dispatch<any>) =>
                          prev === 'dark' ? 'light' : colorTheme
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className=" h-8 w-8 relative inline-flex items-center justify-center rounded-md p-2 bg-black text-black dark:bg-gray-100 focus:outline-none">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only ">Open main menu</span>
                  {open ? (
                    <XMarkIcon
                      className="block h-6 w-6 text-white dark:text-black"
                      aria-hidden="true"
                    />
                  ) : (
                    <Bars3Icon
                      className="block h-6 w-6 text-white dark:text-black"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Disclosure.Panel className="z-[3000] lg:hidden absolute inset-y-0 right-0 overflow-hidden mt-16 bg-white dark:bg-dark w-1/2 max-w-md shadow-2xl">
              <div className="space-y-1 pb-3 pt-2">
                {navItems.map((item) => (
                  <Disclosure.Button
                    key={item.text}
                    as="a"
                    href={item.url}
                    className="focus:outline-none text-center block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 dark:text-white hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 hover:dark:text-gray-700"
                  >
                    {item.text}
                  </Disclosure.Button>
                ))}

                {secondaryNavItems.map((item) => (
                  <Disclosure.Button
                    id={item.id}
                    key={item.text}
                    as="a"
                    href={item.url}
                    className="focus:outline-none text-center block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 dark:text-white hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 hover:dark:text-gray-700"
                  >
                    {item.text}
                  </Disclosure.Button>
                ))}

                <div className="block mx-auto w-8">
                  {mounted && (
                    <ThemeSwitch
                      state={colorTheme === 'dark'}
                      onClick={() =>
                        setTheme((prev: string | Dispatch<any>) =>
                          prev === 'dark' ? 'light' : colorTheme
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export default Navbar
