import { useState } from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ThemeSwitch = (props: { onClick: any; state: boolean }) => {
  const { onClick, state } = props
  const [enabled, setEnabled] = useState(state)

  return (
    <Switch
      checked={enabled}
      onChange={() => {
        setEnabled((prev) => !prev)
        onClick()
      }}
      className={classNames(
        enabled
          ? 'bg-slate-700 text-slate-400 focus-visible:ring-slate-500'
          : 'bg-slate-300 text-cyan-200 focus-visible:ring-cyan-600',
        'h-6 w-11 relative inline-flex justify-between py-1.5 px-2 rounded-full transition-colors duration-300 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white focus:outline-none'
      )}
    >
      <span className="sr-only">Use setting</span>
      <MoonIcon
        className={classNames(
          !enabled ? 'scale-0 duration-500' : 'scale-100 duration-300',
          'h-3 w-3 transform transition-transform fill-white'
        )}
      />

      <SunIcon
        className={classNames(
          !enabled ? 'scale-100 duration-300' : 'scale-0 duration-500',
          'h-3 w-3 transform transition-transform fill-black'
        )}
      />

      <span
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          `absolute top-0.5 left-0.5 bg-white w-5 h-5 rounded-full flex items-center justify-center transition duration-500 transform`
        )}
      >
        <MoonIcon
          className={classNames(
            enabled ? 'opacity-0 scale-0' : 'opacity-100 scale-100',
            'flex-none h-4 w-4 transition duration-500 transform text-cyan-500'
          )}
        />
        <SunIcon
          className={classNames(
            enabled ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
            `flex-none -ml-4 h-4 w-4 transition duration-500 transform text-slate-700`
          )}
        />
      </span>
    </Switch>
  )
}

export default ThemeSwitch

type Props = {
  className: string
}
const MoonIcon = ({ className }: Props) => (
  <svg
    version="1.1"
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
    className={className}
  >
    <g>
      <g>
        <path d="m275.4,500.7c-135,0-244.7-109.8-244.7-244.7 1.06581e-14-134.9 109.8-244.7 244.7-244.7 8.2,0 16.4,0.4 24.6,1.2 7.2,0.7 13.5,5.2 16.5,11.7s2.4,14.2-1.6,20.2c-23,33.8-35.2,73.3-35.2,114.2 0,105 78.7,192.2 183.2,202.6 7.2,0.7 13.5,5.2 16.5,11.7 3.1,6.5 2.4,14.2-1.6,20.2-45.8,67.4-121.4,107.6-202.4,107.6zm-12.5-448c-106.5,6.5-191.2,95.2-191.2,203.3 1.42109e-14,112.3 91.4,203.7 203.7,203.7 56.4,0 109.6-23.4 147.8-63.7-46.2-11.7-88.1-36.8-120.8-72.6-41.1-45.2-63.8-103.6-63.8-164.6 0.1-37.1 8.4-73.2 24.3-106.1z" />
      </g>
    </g>
  </svg>
)

const SunIcon = ({ className }: Props) => (
  <svg
    version="1.1"
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
    className={className}
  >
    <g>
      <g>
        <path d="m256,432.3c-11.3,0-20.4,9.1-20.4,20.4v27.9c0,11.3 9.1,20.4 20.4,20.4s20.4-9.1 20.4-20.4v-27.9c0-11.3-9.1-20.4-20.4-20.4z" />
        <path d="m256,102.5c-84.6,0-153.5,68.8-153.5,153.5 0,84.6 68.8,153.5 153.5,153.5 84.6,0 153.5-68.8 153.5-153.5 0-84.6-68.9-153.5-153.5-153.5zm0,266.1c-62.1,0-112.6-50.5-112.6-112.6 0-62.1 50.5-112.6 112.6-112.6s112.6,50.5 112.6,112.6c0,62.1-50.5,112.6-112.6,112.6z" />
        <path d="M256,79.7c11.3,0,20.4-9.1,20.4-20.4V31.4c0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4v27.9    C235.6,70.6,244.7,79.7,256,79.7z" />
        <path d="m480.6,235.6h-27.9c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h27.9c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4z" />
        <path d="m59.3,235.6h-27.9c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h27.9c11.3,0 20.4-9.1 20.4-20.4 1.42109e-14-11.3-9.1-20.4-20.4-20.4z" />
        <path d="m409.5,131.4l19.7-19.7c8-8 8-20.9 0-28.9-8-8-20.9-8-28.9,0l-19.7,19.7c-8,8-8,20.9 0,28.9s20.9,7.9 28.9,0z" />
        <path d="m102.5,380.6l-19.7,19.7c-8,8-8,20.9 0,28.9 8,8 20.9,8 28.9,0l19.7-19.7c8-8 8-20.9 0-28.9s-20.9-7.9-28.9,0z" />
        <path d="m409.5,380.6c-8-8-20.9-8-28.9,0-8,8-8,20.9 0,28.9l19.7,19.7c8,8 20.9,8 28.9,0 8-8 8-20.9 0-28.9l-19.7-19.7z" />
        <path d="m102.5,131.4c8,8 20.9,8 28.9,0 8-8 8-20.9 0-28.9l-19.7-19.7c-8-8-20.9-8-28.9,0-8,8-8,20.9 0,28.9l19.7,19.7z" />
      </g>
    </g>
  </svg>
)
